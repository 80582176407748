// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v2.4.1
//   protoc               v5.29.0
// source: cbinsights/userv2service/userv2service.proto

/* eslint-disable */

export const protobufPackage = "userv2service";

/** version 5.7.0 */

export enum BooleanOp {
  AND = "AND",
  OR = "OR",
  UNRECOGNIZED = "UNRECOGNIZED",
}

export enum CreateUserSource {
  UNKNOWN_SOURCE = "UNKNOWN_SOURCE",
  ADMIN_SERVICE = "ADMIN_SERVICE",
  /** LTS - Low Touch Sales/Maxio */
  LTS = "LTS",
  /** TRIAL - Trial Automation */
  TRIAL = "TRIAL",
  INVITE = "INVITE",
  LBS = "LBS",
  SCIM = "SCIM",
  COPILOT = "COPILOT",
  UNRECOGNIZED = "UNRECOGNIZED",
}

/** this will be the name of the system or workflow that makes the call to UpdateFeatureCredits or SetUserFeatureCredits */
export enum RequestOrigin {
  UNKNOWN = "UNKNOWN",
  ADMIN = "ADMIN",
  REFUND = "REFUND",
  PLATFORM_DEDUCT = "PLATFORM_DEDUCT",
  PLATFORM_PAYMENT = "PLATFORM_PAYMENT",
  PLATFORM_PAYMENT_FAILURE = "PLATFORM_PAYMENT_FAILURE",
  UNRECOGNIZED = "UNRECOGNIZED",
}

export enum PackageType {
  Unkown_Package_type = "Unkown_Package_type",
  Basic = "Basic",
  Demo = "Demo",
  Paid = "Paid",
  Expired = "Expired",
  Collections_Limited = "Collections_Limited",
  External_Admin = "External_Admin",
  University = "University",
  Editor = "Editor",
  UNRECOGNIZED = "UNRECOGNIZED",
}

export enum UserIsActive {
  Unknown_ActiveStatus = "Unknown_ActiveStatus",
  Active = "Active",
  Inactive = "Inactive",
  UNRECOGNIZED = "UNRECOGNIZED",
}

export enum LoginEventType {
  UNKNOWN_LOGIN_EVENT = "UNKNOWN_LOGIN_EVENT",
  LOGIN_SUCCESS = "LOGIN_SUCCESS",
  LOGIN_FAILURE = "LOGIN_FAILURE",
  LOGOUT = "LOGOUT",
  UNRECOGNIZED = "UNRECOGNIZED",
}

export enum GatedTrialStatus {
  PENDING = "PENDING",
  APPROVED = "APPROVED",
  DENIED = "DENIED",
  UNRECOGNIZED = "UNRECOGNIZED",
}

export interface Empty {
}

export interface Success {
  success: boolean;
}

export interface Id {
  id: number;
}

export interface Ids {
  ids: number[];
}

export interface IdAndName {
  id: number;
  name: string;
}

export interface IdUser {
  idUser: number;
}

export interface IdUsers {
  idUsers: number[];
}

export interface GetUsersRequest {
  includeUserFilters: UserFilter[];
  excludeUserFilters: UserFilter[];
  limit: number;
  offset: number;
}

export interface UserFilter {
  domainFilter?: DomainFilter | undefined;
  idPackageFilter?: IdPackageFilter | undefined;
  idUserFilter?: IdUserFilter | undefined;
  emailFilter?: EmailFilter | undefined;
  idPageFilter?: IdPageFilter | undefined;
  operation: BooleanOp;
}

export interface DomainFilter {
  domains: string[];
}

export interface IdPackageFilter {
  idPackages: number[];
}

export interface IdUserFilter {
  idUsers: number[];
}

export interface EmailFilter {
  emails: string[];
}

export interface IdPageFilter {
  idPages: number[];
}

export interface UserInfo {
  idUser: number;
  email: string;
  firstName: string;
  lastName: string;
  idPackage: number;
  isActive: boolean;
  phone: string;
  title: string;
  teamMemberDetails: TeamMemberDetails | undefined;
  packageStart: string;
  packageEnd: string;
  company: string;
  idPortal: number;
  tsCreated: string;
  recurring: boolean;
  userAvatarInfo?: UserAvatarInfo | undefined;
  teamInfo?: IdAndName | undefined;
}

export interface TeamMemberDetails {
  /** if this is false, then the fields below are not set and are false by default */
  set: boolean;
  owner: boolean;
  admin: boolean;
}

export interface AllUserInfo {
  userInfo: UserInfo[];
}

export interface TeamInfo {
  idTeam: number;
  name: string;
  userInfo: UserInfo[];
}

export interface AllTeamInfo {
  teamInfo: TeamInfo[];
}

export interface UserCreateInfo {
  email: string;
  password: string;
  active: boolean;
  company: string;
  title: string;
  phone: string;
  agreeTos: boolean;
  idPackage: number;
  recurring: boolean;
  packageStart: string;
  packageEnd: string;
  firstName: string;
  lastName: string;
  ipAddress: string;
}

export interface CreateUserRequest {
  user:
    | User
    | undefined;
  /** optional. if not specified, cognito will create a random password */
  password: string;
  source: CreateUserSource;
}

export interface Email {
  email: string;
}

export interface Emails {
  emails: string[];
}

export interface Token {
  token: string;
}

export interface TokenInfo {
  token: string;
  idUser: number;
  email: string;
}

export interface AllTokenInfo {
  tokenInfo: TokenInfo[];
}

export interface LogInSuccess {
  authCode: number;
  userInfo: UserInfo | undefined;
  authToken: string;
}

export interface UpdateUserAvatarRequest {
  image: Uint8Array;
}

export interface UpdateUserAvatarResponse {
  idImage: number;
  url: string;
}

export interface GetUsersAvatarsRequest {
  idUsers: number[];
  width: number;
  height: number;
  scaleToHeight: boolean;
  scaleUp: boolean;
  getMeta: boolean;
}

export interface GetUsersAvatarsResponse {
  userAvatarInfo: UserAvatarInfo[];
}

export interface UserAvatarInfo {
  idUser: number;
  idImage: number;
  url: string;
}

export interface GuestUserInfo {
  userInfo: UserInfo | undefined;
  newlyCreated: boolean;
  singleUseLoginToken: string;
}

export interface AllGuestUserInfo {
  guestUserInfo: GuestUserInfo[];
}

export interface GetUsersInfoByIdRequest {
  idUsers: number[];
  includeAvatars: boolean;
}

export interface NavigationStructure {
  stories: NavigationSection | undefined;
  research: NavigationSection | undefined;
  advancedSearch: NavigationSection | undefined;
  workFaster: NavigationSection | undefined;
  industryAnalytics: NavigationSection | undefined;
}

export interface CollectionsSection {
  type: string;
  content: string;
  sectionDisplayType: string;
  items: CollectionItem[];
  feature: string;
}

export interface CollectionItem {
  feature: string;
  content: string;
  itemDisplayType: string;
  type: string;
  idCollection: number;
  isPinned: boolean;
  position: number;
}

export interface NavigationItem {
  feature: string;
  content: string;
  itemDisplayType: string;
  type: string;
}

export interface NavigationSection {
  type: string;
  content: string;
  sectionDisplayType: string;
  items: NavigationItem[];
  feature: string;
}

export interface SearchByEmailRequest {
  searchTerm: string;
  limit: number;
  getExpiredUsers: boolean;
  onlyEmail: boolean;
}

export interface idPage {
  idPage: number;
}

export interface GetUserAdminFeaturesByIdPageForRoleResponse {
  idRole: number;
  features: string[];
}

export interface UserFeatureRequest {
  idFeature: number;
  idUser: number;
  asset: string;
}

export interface UpdateFeatureCreditsRequest {
  idFeature: number;
  usedCredits: number;
  idUser: number;
  origin: RequestOrigin;
}

export interface SetUserFeatureCreditsRequest {
  idUser: number;
  featureToCreditUsage: { [key: number]: number };
  origin: RequestOrigin;
}

export interface SetUserFeatureCreditsRequest_FeatureToCreditUsageEntry {
  key: number;
  value: number;
}

export interface TeamUserQuota {
  id_user: number;
  id_team: number;
  id_feature: number;
  quota: number;
  remove_quota: boolean;
}

export interface SetTeamUsersQuotasRequest {
  team_user_quotas: TeamUserQuota[];
}

export interface GetTeamFeatureUsageRequest {
  id_team: number;
}

export interface GetTeamFeatureUsageResponse {
  team_feature_usages: TeamFeatureUsage[];
}

export interface GetTeamKnownDomainsRequest {
  id_team: number;
}

export interface GetTeamKnownDomainsResponse {
  known_domains: string[];
}

export interface TeamFeatureUsage {
  id_team: number;
  id_feature: number;
  total: number;
  package_quota: number;
  additional_quota: number;
}

export interface SetTeamFeatureUsageRequest {
  team_feature_usages: TeamFeatureUsage[];
}

export interface UserFeatureCredits {
  featureCredits: number;
  featureCreditsAllotted: number;
  idFeature: number;
  claimedAsset: ClaimedAsset | undefined;
}

export interface ClaimedAsset {
  idUser: number;
  idTeam: number;
  asset: string;
  tsClaimed: string;
}

export interface AllUserFeatureCreditsRequest {
  id_user: number;
}

export interface AllUserFeatureCredits {
  user_feature_credits: UserFeatureCredits[];
}

export interface GetUserLevelFeatureUsageAndQuotaRequest {
  id_users: number[];
  id_feature: number;
}

export interface GetUserLevelFeatureUsageAndQuotaResponse {
  usages_and_quotas: FeatureUsageAndQuota[];
}

export interface FeatureUsageAndQuota {
  id_user: number;
  id_feature: number;
  quota: number;
  usage: number;
  feature_name: string;
  feature_key: string;
  package_limit: number;
}

export interface UpgradeRequestInfo {
  url: string;
  email: string;
  firstName: string;
  lastName: string;
  jobTitle: string;
  phone: string;
  lookingToLearn: string;
}

export interface SuccessStatus {
  successStatus: boolean;
}

export interface UserID {
  idUser: number;
}

export interface UserAndTrialInfo {
  idUser: number;
  email: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  title: string;
  company: string;
  trialInfo: trialInfo | undefined;
  idPackage: number;
  isTeamAdmin: boolean;
  idTeam: number;
  segmentId: string;
  packageName: string;
  packageStart: string;
  packageEnd: string;
  packageType: string;
  isTeamOwner: boolean;
}

export interface UserVerificationInfo {
  id_user: number;
  email: string;
  first_name: string;
  last_name: string;
  phone_number: string;
  title: string;
  company: string;
  trial_info: trialInfo | undefined;
  id_package: number;
  is_team_admin: boolean;
  id_team: number;
  segment_id: string;
  package_name: string;
  package_start: string;
  package_end: string;
  package_type: string;
  features: FeatureFlag[];
}

export interface trialInfo {
  isUserOnTrial: boolean;
  hasUserRequestedDemo: boolean;
  dateOfTrialRequest: string;
  numberOfDaysLeftOnTrial: number;
  hasCollectionUserRequestedDemo: boolean;
}

export interface FeatureFlag {
  id_feature: number;
  feature: string;
  feature_session: string;
  access: number;
  quota: number;
  results_per_page: number;
  results_total: number;
}

export interface Feature {
  id_feature: number;
  /** The user friendly name of the feature flag */
  display_name: string;
  /** The snaked_cased_name of the feature flag. Previously known as feature_session */
  name: string;
  description: string;
  is_addon: boolean;
}

export interface UserFeatureFlagsResponse {
  features: FeatureFlag[];
}

export interface UpdatePasswordRequest {
  oldPassword: string;
  newPassword: string;
  /**
   * for AWS Cognito JWTs, this is the ID token
   * for auth-service JWTs, this is the regular (access) JWT
   */
  token: string;
  /** this is only for AWS Cognito JWTs, it is the Cognito access token */
  accessToken: string;
}

export interface SuccessStatusMessage {
  successStatus: boolean;
  message: string;
}

export interface UpgradeUsersBasePackageRequest {
  idUsers: number[];
}

export interface DowngradeUsersBasePackageRequest {
  idUsers: number[];
}

export interface UpdateUserAccessRequest {
  rangeStart: string;
  rangeEnd: string;
}

export interface UpdateUserAccessResponse {
  success: boolean;
}

export interface GetTeamsRequest {
  offset: number;
  limit: number;
}

export interface GetTeamsResponse {
  teams: TeamSummary[];
}

export interface TeamSummary {
  idTeam: number;
  name: string;
  memberCap: number;
  activeMemberCount: number;
  linkBasedSignupEnabled: boolean;
}

export interface CreateTeamRequest {
  id_organization: number;
  id_package: number;
  name: string;
  member_cap: number;
  package_start: string;
  package_end: string;
  recurring: boolean;
  self_service: boolean;
  id_customer_external: string;
}

export interface AddUserToTeamRequest {
  id_user: number;
  id_team: number;
  is_admin: boolean;
  is_owner: boolean;
}

export interface RemoveUserFromTeamRequest {
  id_user: number;
  id_team: number;
}

export interface UpdateUserTeamRequest {
  update_mask: string[] | undefined;
  user_team: UserTeam | undefined;
}

export interface UserTeam {
  id_organization: number;
  id_user: number;
  id_team: number;
  owner: boolean;
  admin_priv: boolean;
  share_all: boolean;
}

export interface UpdateTeamRequest {
  update_mask: string[] | undefined;
  team: Team | undefined;
  id_initiator: number;
}

export interface SearchForTeamsRequest {
  term: string;
  limit: number;
  offset: number;
}

export interface SearchForTeamsResponse {
  teams: TeamSuggestion[];
}

export interface TeamSuggestion {
  id_team: number;
  name: string;
  is_archived: boolean;
}

export interface Team {
  id_team: number;
  name: string;
  test_team: boolean;
  id_package: number;
  id_organization: number;
  member_cap: number;
  package_start: string;
  package_end: string;
  recurring: boolean;
  anand_signup_email: boolean;
  salesforce_account_id: string;
  ts_created: string;
  salesforce_package_price: number;
  salesforce_owner: string;
  renewal_date: string;
  customer_use_case: string;
  cbi_account_sector: string;
  cs_rep_email: string;
  self_service: boolean;
  /** the external id that the team is linked to (initially maxio customer id) */
  id_customer_external: string;
  nonadmin_invite_enabled: boolean;
}

export interface GetTeamMembersRequest {
  id_team: number;
  get_former_members: boolean;
}

export interface GetTeamMemberResponse {
  id_team: number;
  current_members: number[];
  former_members: number[];
}

export interface GetTeamByExternalIdRequest {
  id_customer_external: number;
}

export interface UserSessionData {
  idUser: number;
  email: string;
  firstName: string;
  lastName: string;
  isAdmin: boolean;
  idPackage: number;
  packageType: PackageType;
  isExternalAdmin: boolean;
  segmentId: string;
  adminType: string;
  isActive: boolean;
}

export interface ValidateEmailRequest {
  email: string;
  url: string;
  ipAddress: string;
  context: string;
  isResearch: boolean;
}

export interface ValidateEmailResponse {
  isValid: boolean;
}

export interface GetAdminUserPermissionsRequest {
  idUser: number;
}

export interface GetAdminUserPermissionsResponse {
  pageIds: number[];
}

export interface TeamAdminInfoResponse {
  adminInfo: TeamAdminUserInfo[];
}

export interface TeamAdminUserInfo {
  idUser: number;
  email: string;
  firstName: string;
  lastName: string;
}

export interface FeatureInfo {
  idFeature: number;
  access: number;
  quota: number;
  resultsPerPage: number;
  resultsTotal: number;
}

export interface FeatureInfoRequest {
  idUser: number;
  idFeature: number;
}

export interface TeamAdminInfo {
  adminInfo: TeamAdminUserInfo[];
}

export interface UserSignupInfo {
  email: string;
  password: string;
  is_active: boolean;
  company: string;
  title: string;
  phone: string;
  agree_tos: boolean;
  id_package: number;
  recurring: boolean;
  package_start: string;
  package_end: string;
  first_name: string;
  last_name: string;
  ip_address: string;
  send_activation_email: boolean;
  clone_collections: boolean;
  subscribe_to_newsletter: boolean;
  lbs_code: string;
  university_slug: string;
  source: string;
  bypass_email_validation: boolean;
}

export interface UserSignupInfoResponse {
  id_user: number;
  email: string;
  first_name: string;
  last_name: string;
  title: string;
  phone: string;
  is_admin: boolean;
  admin_type: string;
  id_portal: number;
  id_segment: string;
  is_active: boolean;
  package_type: PackageType;
}

export interface CreateApprovedUserByEmailResponse {
  idUser: number;
  email: string;
  approved: boolean;
  removed: boolean;
}

export interface HandleUserMigrationEventRequest {
  email: string;
  password: string;
  user_pool_id: string;
}

export interface GetCognitoDataRequest {
  email: string;
  id_team: number;
}

export interface DestroyTokenRequest {
  token: string;
}

export interface DestroyTokenResponse {
  success: boolean;
}

export interface HandlePreAuthenticationRequest {
  email: string;
  ip_address: string;
}

export interface CreateUniversityRequest {
  id_user: number;
  university_name: string;
  domains: UniversityDomain[];
  ip_ranges: UniversityIpRange[];
  id_package: number;
  slug: string;
  activate_url: string;
  image_url: string;
  admin_notes: string;
  start_date: string;
  expire_date: string;
  secondary_header: string;
}

export interface UpdateUniversityRequest {
  id_user: number;
  id_university: number;
  ip_ranges: UniversityIpRange[];
  domains: UniversityDomain[];
  id_package: number;
  start_date: string;
  expire_date: string;
  slug: string;
  image_url: string;
  university_name: string;
  secondary_header: string;
  activate_url: string;
}

/** user pool and app client id for given user email or default values */
export interface GetCognitoDataResponse {
  /** the AWS Cognito user pool id */
  user_pool_id: string;
  /** the AWS Cognito app client id that can be used to communicate with this pool */
  app_client_id: string;
  /** Fields specific to the SSO user, which are required for logging in */
  cognito_domain: string;
  identity_provider: string;
  redirect_uri: string;
  response_type: string;
  scope: string;
}

/** information about MFA being required for a user */
export interface MfaRequiredForUserResponse {
  /** true if MFA is required for the user */
  mfa_required: boolean;
  /** the reason why MFA is required for the user if mfa_required is true */
  mfa_required_reason: string;
}

export interface HandleCustomMessageEventRequest {
  email: string;
  university_slug: string;
}

export interface HandleCustomMessageEventResponse {
  activation_url: string;
}

export interface ListUniversitiesRequest {
  /** Not used yet. These will be used for future filtering */
  page_size: number;
  page_token: string;
}

export interface ListUniversitiesResponse {
  universities: UniversityData[];
}

export interface UniversityData {
  id_university: number;
  university: string;
  id_package: number;
  start_date: string;
  end_date: string;
  ip_ranges: UniversityIpRange[];
  domain_info: UniversityDomain[];
  university_slug: string;
  subscription_type: string;
  image_url: string;
  package: string;
  activate_url: string;
  secondary_header: string;
}

export interface UniversityIpRange {
  ip_range: string;
  ip_type: string;
}

export interface UniversityDomain {
  sub_domain: string;
  domain: string;
  suffix: string;
  is_blocked: boolean;
}

export interface ListPackagesRequest {
  /**
   * The maximum number of packages to return. The service may return fewer than
   * this value.
   * If unspecified, at most 100 packages will be returned.
   * The maximum value is 1000; values above 1000 will be coerced to 1000.
   */
  page_size: number;
  page_token: string;
  include_deleted: boolean;
}

export interface ListPackagesResponse {
  packages: Package[];
  next_page_token: string;
}

export interface SendCsPackageInquiryRequest {
  request_text: string;
  feature_name: string;
}

export interface GetPackageRequest {
  id_package: number;
  include_deleted: boolean;
}

export interface Package {
  id_package: number;
  name: string;
  annual: boolean;
  id_duration: number;
  price: number;
  package_type: string;
  create_time: Date | undefined;
  num_days: number;
  deleted: boolean;
}

export interface ResetPasswordRequest {
  token: string;
  new_password: string;
}

/** need this weird structure because web-cbi uses those properties in the code to return custom user responses */
export interface ResetPasswordResponse {
  email: string;
  status: string;
}

export interface DeactivateUniversityRequest {
  id_university: number;
}

export interface UniversityUsageData {
  email: string;
  last_name: string;
  first_name: string;
  company: string;
  total_logins: number;
  com_searches: number;
  inv_searches: number;
  rank_searches: number;
  cp_views: number;
  ip_views: number;
  ia_views: number;
  deal_searches: number;
  total_searches: number;
  total_profiles: number;
  ex_downloads: number;
  sf_tos: number;
  sf_contacts: number;
  vs_searches: number;
  people_searches: number;
}

export interface FetchUniversityUsageDataRequest {
  id_university: number;
}

export interface FetchUniversityUsageDataResponse {
  usage_data: UniversityUsageData[];
}

export interface ResetTokenInfoResponse {
  email: string;
  idUser: number;
  isLinkExpired: number;
}

export interface GetTeamInfoByIdsRequest {
  ids: number[];
  include_expired_users: boolean;
}

export interface UpdateUserRequest {
  user: User | undefined;
  update_mask:
    | string[]
    | undefined;
  /** @deprecated */
  id_user: number;
  /** @deprecated */
  email: string;
  /** @deprecated */
  first_name: string;
  /** @deprecated */
  last_name: string;
  /** @deprecated */
  active: UserIsActive;
}

export interface BatchUpdateUserPackageRequest {
  id_users: number[];
  package: PackageAndDates | undefined;
  id_initiator: number;
}

export interface PackageAndDates {
  id_package: number;
  package_start: string;
  package_end: string;
}

export interface GetExpiredUsersToDeactivateRequest {
}

export interface GetExpiredUsersToDeactivateResponse {
  users: User[];
}

export interface ExpireUsersRequest {
  id_users: number[];
}

export interface User {
  id_user: number;
  email: string;
  first_name: string;
  last_name: string;
  id_package: number;
  is_active: boolean;
  title: string;
  agree_tos: boolean;
  recurring: boolean;
  package_start: string;
  package_end: string;
  id_portal: number;
  company: string;
}

export interface CreateZendeskTokenRequest {
  email: string;
  first_name: string;
  last_name: string;
  brand_id: string;
}

export interface DemoRequest {
  id_user: number;
  email: string;
  first_name: string;
  last_name: string;
  phone_number: string;
  job_title: string;
  company: string;
  looking_to_do: string;
  url: string;
  team_function: string;
}

export interface DeleteUniversityRequest {
  id_university: number;
}

export interface GetUserClearbitDataByEmailResponse {
  successStatus: boolean;
  missingFields: string[];
}

export interface GetUserLoginHistoryRequest {
  id_user: number;
  limit: number;
}

export interface GetUserLoginHistoryResponse {
  login_attempts: LoginAttempt[];
}

export interface LoginAttempt {
  id_user: number;
  ts_event: string;
  event_type: LoginEventType;
  message: string;
}

export interface GatedTrialUser {
  email: string;
  approval_status: GatedTrialStatus;
  ts_created: string;
  ts_updated: string;
  first_name: string;
  last_name: string;
  phone: string;
  company: string;
  title: string;
  university_slug: string;
  ip_address: string;
  lbs_code: string;
  reason: string;
  source: string;
}

export interface ListGatedTrialUsersRequest {
  offset: number;
  limit: number;
  approval_status_filter: GatedTrialStatus[];
  email_filter: string;
}

export interface ListGatedTrialUsersResponse {
  users: GatedTrialUser[];
}

export interface UpdateGatedTrialApprovalStatusRequest {
  email: string;
  approval_status: GatedTrialStatus;
  reason: string;
  /** specify a package for approved users */
  id_package: number;
}

export interface GetUserTrialRequest {
  id_user: number;
}

export interface GetUserTrialResponse {
  ts_trial_package_end: string;
  ts_trial_package_start: string;
  id_package_trial: number;
  id_package_trial_original: number;
  package_trial_name: string;
  package_trial_original_name: string;
  trial_specific_features: FeatureFlag[];
}

export interface DeleteUserTrialByIdUserRequest {
  id_user: number;
}

export interface CreateUserTrialRequest {
  id_user: number;
  id_package_trial: number;
  ts_trial_package_start: string;
  ts_trial_package_end: string;
}

export interface UserTrial {
  id_user_trial_package: number;
  id_user: number;
  id_package_trial: number;
  id_package_trial_original: number;
  ts_trial_package_start: string;
  ts_trial_package_end: string;
}

export interface ListUserTrialRequest {
  id_user: number;
}

export interface ListUserTrialResponse {
  user_trials: UserTrial[];
}

export interface DeleteUserTrialRequest {
  id_user: number;
  id_user_trial_package: number;
}

export interface UpdateUserTrialRequest {
  id_user: number;
  id_user_trial_package: number;
  id_package_trial: number;
  id_package_trial_original: number;
  ts_trial_package_start: string;
  ts_trial_package_end: string;
}

export interface ListUserTrialsForUpdateRequest {
  ts_now: string;
}

export interface ListUserTrialsForUpdateResponse {
  update_to_trial: UserTrial[];
  update_to_original: UserTrial[];
}

export interface SendWelcomeEmailRequest {
  id_user: number;
}

export interface SendActivationEmailRequest {
  id_user: number;
}

export interface ToggleTeamAddonRequest {
  id_team: number;
  id_feature: number;
  enabled: boolean;
}

export interface GetTeamAddonsRequest {
  id_team: number;
}

export interface GetTeamAddonsResponse {
  addons: Addon[];
}

export interface Addon {
  id_feature: number;
  enabled: boolean;
  included_in_package: boolean;
  feature: Feature | undefined;
}

export interface GetTeamSalesforceInfoResponse {
  id_team: number;
  cs_rep_email: string;
  champion_email: string;
  economic_buyer_email: string;
  account_nickname: string;
}

export interface GetUserPackageHistoryRequest {
  id_user: number;
  limit: number;
}

export interface UserPackageHistory {
  id_user: number;
  id_package: number;
  package_name: string;
  type: string;
  package_start: string;
  package_end: string;
  ts_log: string;
  initiator_id: number;
  initiator_email: string;
  first_name: string;
  last_name: string;
}

export interface GetUserPackageHistoryResponse {
  user_package_histories: UserPackageHistory[];
}

export interface GetPasswordResetTokenRequest {
  email: string;
}

export interface GetPasswordResetTokenResponse {
  token: string;
  timestamp: string;
}

export interface Portal {
  id_portal: number;
  name: string;
  slug: string;
  id_package: number;
}

export interface GetPortalsResponse {
  portals: Portal[];
}

export interface SetTeamAdditionalQuotaRequest {
  id_team: number;
  id_feature: number;
  additional_quota: number;
}

export interface ProvisionSCIMTenantRequest {
  id_team: number;
  enabled: boolean;
  generate_api_key: boolean;
}

export interface ProvisionSCIMTenantResponse {
  id_tenant: number;
  api_key: string;
}

export interface RevokeSCIMAPIKeyRequest {
  id_team: number;
}

export interface GenerateSCIMAPIKeyRequest {
  id_team: number;
}

export interface GenerateSCIMAPIKeyResponse {
  api_key: string;
}

export interface GetSCIMAPIKeysRequest {
  id_team: number;
}

export interface GetSCIMAPIKeysResponse {
  scim_api_keys: SCIMAPIKey[];
}

export interface SCIMAPIKey {
  api_key_prefix: string;
  is_expired: boolean;
  created_at: string;
  expiry_date: string;
}

export interface SendPasswordResetEmailRequest {
  email: string;
  is_gated_trial: boolean;
}

export interface GetCopilotTeamUsingDomainRequest {
  domain: string;
}

export interface SetTeamTypeRequest {
  id_team: number;
  team_type: SetTeamTypeRequest_TeamType;
}

export enum SetTeamTypeRequest_TeamType {
  REGULAR = "REGULAR",
  COPILOT = "COPILOT",
  UNRECOGNIZED = "UNRECOGNIZED",
}
