import { Form } from 'antd';
import { CbiEntityRoutes } from 'client/modules/cbi-entity/RoutesHelpers';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import {
  GetTeamResponse,
  UserTeamUsage,
} from '@cbinsights/adminservice/adminservice';
import { useGetTeamQuery } from '../services/hooks/useGetTeamQuery';
import { FEATURE_IDS } from '../constants/features';

export type AddonDetail = {
  enabled: boolean;
  includedInPackage: boolean;
};

export type TeamForm = {
  name: string;
  packageEnd: string;
  packageStart: string;
  packageID?: number;
  packageName?: string;
  domainWhitelist?: string;
  mfaStatus?: boolean;
  ssoStatus?: AddonDetail;
  teamUsages?: Array<UserTeamUsage>;
  teamId?: number;
  seatLimit?: number;
  numberOfUsers?: number;
  lbsStatus: GetTeamResponse['signup_code'];
  sfOwner?: string;
  csRepEmail?: string;
  sfAccountId?: string;
  renewalDate?: string;
  recordedDate?: string;
  teamType?: string;
};

const getAddons = (team: GetTeamResponse) => {
  const addons = team?.addons || [];

  const sso = addons.find((addon) => addon.id_feature === FEATURE_IDS.SSO);

  return {
    sso: { enabled: sso?.enabled, includedInPackage: sso?.included_in_package },
  };
};

export const useTeamForm = () => {
  const [form] = Form.useForm<TeamForm>();

  const { id } = useParams<typeof CbiEntityRoutes.Id>();

  const {
    data: team,
    refetch: refetchGetTeam,
    isLoading,
  } = useGetTeamQuery({
    enabled: Boolean(id),
    params: { id_team: Number(id) },
  });

  useEffect(() => {
    if (!team) {
      return;
    }

    form.setFieldsValue({
      name: team.team.name,
      packageEnd: team.team.packageEnd,
      packageStart: team.team.packageStart,
      packageID: team.team.idPackage,
      packageName: team.team.packageName,
      domainWhitelist: team.signup_code.domain_whitelist.join(', '),
      mfaStatus: team.mfa_status.mfa_enabled,
      teamUsages: team.team_usages,
      teamType: team.team.teamType,
      teamId: Number(id),
    });
  }, [team]);

  const initialValues: TeamForm = {
    domainWhitelist: team?.signup_code.domain_whitelist.join(', '),
    teamUsages: [],
    mfaStatus: team?.mfa_status.mfa_enabled,
    name: team?.team.name,
    packageEnd: team?.team?.packageEnd,
    packageID: team?.team.idPackage,
    packageName: team?.team.packageName,
    packageStart: team?.team?.packageStart,
    teamId: Number(id),
    seatLimit: team?.team?.memberCap,
    numberOfUsers: team?.team?.numberOfUsers,
    ssoStatus: getAddons(team).sso,
    lbsStatus: team?.signup_code,
    sfOwner: team?.team?.sfOwner,
    csRepEmail: team?.team?.csRepEmail,
    sfAccountId: team?.team?.sfAccountId,
    renewalDate: team?.team?.renewalDate,
    recordedDate: team?.team?.tsCreated,
    teamType: team?.team?.teamType,
  };

  return {
    form,
    fields: {},
    initialValues,
    idTeam: Number(id),
    refetchGetTeam,
    isLoading,
  };
};

export default useTeamForm;
