// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v2.4.1
//   protoc               v5.29.0
// source: cbinsights/businessrelationshipservice/businessrelationshipservice.proto

/* eslint-disable */

export const protobufPackage = "businessrelationshipservice";

/** version 7.6.0 */

export enum ApprovalStatus {
  Unknown_ApprovalStatus = "Unknown_ApprovalStatus",
  NeedsReview = "NeedsReview",
  Approved = "Approved",
  Rejected = "Rejected",
  Deleted = "Deleted",
  PendingApproved = "PendingApproved",
  PendingRejected = "PendingRejected",
  Merged = "Merged",
  UNRECOGNIZED = "UNRECOGNIZED",
}

export enum PlayerType {
  Unknown_PlayerType = "Unknown_PlayerType",
  Company = "Company",
  Investor = "Investor",
  IdCbiEntity = "IdCbiEntity",
  UNRECOGNIZED = "UNRECOGNIZED",
}

export enum Priority {
  ALL = "ALL",
  HighPriority = "HighPriority",
  LowPriority = "LowPriority",
  UNRECOGNIZED = "UNRECOGNIZED",
}

export enum PlayerEntityTypeFilter {
  Unknown_PlayerEntityType = "Unknown_PlayerEntityType",
  AllPlayerTypes = "AllPlayerTypes",
  NewPlayers = "NewPlayers",
  ExistingPlayers = "ExistingPlayers",
  UNRECOGNIZED = "UNRECOGNIZED",
}

export interface Empty {
}

export interface SuccessStatus {
  success: boolean;
}

export interface Player {
  idPlayer: number;
  name: string;
  url: string;
  idCbiEntity: number;
  country: string;
}

export interface PlayerRole {
  id: number;
  name: string;
}

export interface News {
  id: number;
  idNews: number;
  title: string;
  link: string;
  sentences: string[];
  contentPreview: string;
  date: string;
}

export interface PlayerDetail {
  id: number;
  player: Player | undefined;
  role: PlayerRole | undefined;
}

export interface Relationship {
  id: number;
  startDate: string;
  status: ApprovalStatus;
  playerDetails: PlayerDetail[];
  news: News[];
  lastUpdatedTS: string;
  lastUpdatedBy: User | undefined;
  lastUpdatedUUID: string;
  lastViewedInfo: RelationshipLastViewedInfo | undefined;
  idRaw: number;
  note: string;
  lastExternalUserToUpdate: RelationshipLastUpdateInfo | undefined;
  lastInternalUserToUpdate: RelationshipLastUpdateInfo | undefined;
  dupesCount: number;
  vendorSourced: boolean;
  isCorrect?: boolean | undefined;
  excludedFromPerformanceScore?: boolean | undefined;
  partnershipBenefit: string;
  summaryJson: string;
}

export interface RelationshipLastViewedInfo {
  lastViewedBy: User | undefined;
  lastViewedTS: string;
  isLocked: boolean;
}

export interface RelationshipLastUpdateInfo {
  lastUpdatedBy: User | undefined;
  lastUpdatedTS: string;
}

export interface User {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
}

export interface GetRelationshipsRequest {
  players: Player[];
  idRelationships: number[];
  idUsers: number[];
  status: ApprovalStatus;
  offset: number;
  limit: number;
  sortField: string;
  sortDirection: string;
  verticalFilterId: number;
  playerEntityTypeFilter: PlayerEntityTypeFilter;
  priorityFilter: Priority;
  playerRoleFilter: number;
  ignoreStatus: boolean;
  daysAfterInsertUntilLive: number;
  includeSummaries: boolean;
}

export interface GetRelationshipResponseInfo {
  relationships: Relationship[];
}

export interface GetRelationshipsResponse {
  unlockedRelationships: GetRelationshipResponseInfo | undefined;
  allRelationships: GetRelationshipResponseInfo | undefined;
}

export interface GetRelationshipCountsResponse {
  unlockedCountPerStatus: TotalCountPerStatus | undefined;
  allCountPerStatus: TotalCountPerStatus | undefined;
}

export interface TotalCountPerStatus {
  needsReview: number;
  rejected: number;
  approved: number;
  pendingApproved: number;
  pendingRejected: number;
}

export interface ListOfPlayers {
  players: Player[];
}

export interface ListOfPlayerRoles {
  roles: PlayerRole[];
}

export interface SaveRelationshipsRequest {
  status: ApprovalStatus;
  relationships: Relationship[];
  attemptMerge: boolean;
  skipPlayerUpdates: boolean;
}

export interface UpdateRelationshipsResponse {
  success: boolean;
  message: string;
  failedRows: Relationship[];
}

export interface NewRelationshipResponse {
  tempId: number;
  newId: number;
  failureReason: string;
}

export interface CreateNewRelationshipsResponse {
  newRelationships: NewRelationshipResponse[];
}

export interface CbiEntity {
  idCbiEntity: number;
  type: PlayerType;
}

export interface CbiEntities {
  cbiEntities: CbiEntity[];
}

export interface CbiEntityPlayerInfo {
  cbiEntitityPlayerInfo: Player[];
}

export interface UnlockRelationshipsRequest {
  idRelationships: number[];
  idUsers: number[];
}

export interface NewPlayerNames {
  playerNames: string[];
}

export interface UpdateRelationshipsStatusRequest {
  idRelationships: number[];
  status: ApprovalStatus;
}

export interface UpdateRelationshipsStatusResponse {
  success: boolean;
}

export interface RawRelationship {
  id: number;
  idPartner1: number;
  partner1: string;
  partner1EntityType: string;
  idPartner2: number;
  partner2: string;
  partner2EntityType: string;
  idNews: number;
  partnershipDate: string;
  partnershipType: number;
}

export interface CreateRawRelationshipsRequest {
  rawRelationships: RawRelationship[];
}

export interface GetRawRelationshipsByIdRequest {
  ids: number[];
}

export interface GetRawRelationshipsByIdResponse {
  rawRelationships: RawRelationship[];
}

export interface GetNewsFullArticleRequest {
  idNews: number[];
}

export interface NewsArticle {
  idNews: number;
  title: string;
  link: string;
  sentences: string[];
  content: string;
}

export interface GetNewsFullArticleResponse {
  news: NewsArticle[];
}

export interface GetUserRoleFeaturesResponse {
  idRole: number;
  features: string[];
}

export interface GetDuplicateRelationshipsRequest {
  relationships: Relationship[];
  daysAfterInsertUntilLive: number;
}

export interface DuplicateRelationshipsResponse {
  duplicateRelationships: DuplicateRelationships[];
}

export interface DuplicateRelationships {
  idRelationship: number;
  relationships: Relationship[];
  totalCountPerStatus: TotalCountPerStatus | undefined;
}

export interface VerticalFilterInfoResponse {
  verticalFilters: VerticalFilter[];
}

export interface VerticalFilter {
  id: number;
  name: string;
}

export interface PlayerNames {
  names: string[];
}

export interface GetPlayerIdsByNamesResponse {
  playerInfo: Player[];
}

export interface MergeRelationshipsRequest {
  currentViewRelationship: Relationship | undefined;
  mergeRelationships: Relationship[];
}

export interface MergeRelationshipsResponse {
  success: boolean;
  idParentRelationship: number;
  parentRelationshipStatus: ApprovalStatus;
  idChildRelationship: number;
  idMergedRelationships: number[];
}

export interface GetRelationshipsForCbiEntityRequest {
  idCompany: number;
  idInvestor: number;
  offset: number;
  limit: number;
  sortField: string;
  sortDirection: string;
  startDate: string;
  endDate: string;
  playersFilter: number[];
  playerRolesFilter: number[];
  countryFilter: string;
  idCbiEntity: number;
  getOnlyRelationships: boolean;
  getOnlyPartners: boolean;
  lastUpdateTime: string;
  getOnlyActive: boolean;
  excludedPlayerRolesFilter: number[];
}

export interface GetRelationshipsForCbiEntityResponse {
  relationships: BusinessRelationship[];
  numResults: number;
  totalNumResults: number;
  allPartners: Player[];
}

export interface BusinessPartner {
  /** id company or id investor which is required by other service to generate profile url */
  idEntity: number;
  /** we get the idCbiEntity, idEntityType through the cbi entity service call */
  idEntityType: number;
  playerRole: string;
  name: string;
  country: string;
  idCbiEntity: number;
}

export interface NewsSnippet {
  title: string;
  url: string;
  content: string;
}

export interface NewsSource {
  title: string;
  url: string;
  source: string;
  date: string;
}

export interface BusinessRelationship {
  idRelationship: number;
  startDate: string;
  businessPartners: BusinessPartner[];
  newsSnippet: NewsSnippet | undefined;
  sources: NewsSource[];
  lastUpdateTime: string;
  vendorSourced: boolean;
  partnershipBenefit: string;
}

export interface PlayerCbiInfo {
  idPlayer: number;
  name: string;
  country: string;
  active: boolean;
}

export interface CreateNewPlayerCbiInfoRequest {
  playerCbiInfo: PlayerCbiInfo[];
}

export interface NewPlayerCbiInfoResponse {
  idPlayer: number;
  name: string;
  country: string;
  failureReason: string;
  active: boolean;
}

export interface CreateNewPlayerCbiInfoResponse {
  newPlayerCbiInfo: NewPlayerCbiInfoResponse[];
}

export interface GetExternalUsersAuditInfoRequest {
  idUsers: number[];
  startDate: string;
  endDate: string;
  minutesAllowedToMakePostReviewChanges: number;
  secondsNeededToAccountForAuditInsertTiming: number;
  validateDates: boolean;
  onlyGenerateSummaryReport: boolean;
}

export interface GetExternalUsersAuditInfoResponse {
  userAuditInfoResponse: UserAuditInfoResponse[];
}

export interface UserAuditInfoResponse {
  idUser: number;
  email: string;
  summaryInfo: SummaryInfo | undefined;
  incorrectRelationshipDetails: IncorrectRelationshipDetails[];
}

export interface SummaryInfo {
  totalNumberUpdated: number;
  totalNumberReviewed: number;
  totalNumberOfCorrectRelationships: number;
  totalNumberOfIncorrectRelationships: number;
  totalCorrectRelationshipsExcludingBulk: number;
  totalIncorrectRelationshipsExcludingBulk: number;
}

export interface IncorrectRelationshipDetails {
  idRelationship: number;
  correctStatus: ApprovalStatus;
  incorrectStatus: ApprovalStatus;
  isStatusCorrect: boolean;
  correctStartDate: string;
  incorrectStartDate: string;
  correctEndDate: string;
  incorrectEndDate: string;
  playerInfo: PlayerDetailedInfo[];
  internalUserWhoMarkedAsIncorrect: string;
  tsOfInternalUserMark: string;
  newsArticle: string;
}

export interface PlayerDetailedInfo {
  correctPlayerName: string;
  correctIdPlayer: number;
  incorrectPlayerName: string;
  incorrectIdPlayer: number;
  correctPlayerRole: string;
  incorrectPlayerRole: string;
  markedIncorrectByCbiUser: boolean;
}

export interface GetAllExternalUsersRequest {
  onlyGetNonCbiUsers: boolean;
}

export interface AllExternalUsers {
  users: User[];
}

export interface UpdateVendorPlayerSelectionInfoRequest {
  idRelationshipPlayer: number;
  isCorrect: boolean;
}

export interface SetVendorRelationshipCorrectnessRequest {
  idRelationship: number;
  isCorrect: boolean;
  reason: string;
  excludedFromPerformanceScore: boolean;
}

export interface Entity {
  idCompany: number;
  idInvestor: number;
  idCbiEntity: number;
}

export interface GetRecentRelationshipsForCbiEntitiesRequest {
  entities: Entity[];
  mostRecentCount: number;
  playerRolesFilter: number[];
  excludeNews: boolean;
}

export interface SimplifiedBusinessRelationship {
  startDate: string;
  businessPartners: BusinessPartner[];
  source: string;
}

export interface EntityRelationships {
  entity: Entity | undefined;
  relationships: SimplifiedBusinessRelationship[];
  otherPlayersTotalCount: number;
}

export interface GetRecentRelationshipsForCbiEntitiesResponse {
  recentRelationships: EntityRelationships[];
}

export interface UploadCSVFileRequest {
  csv: Uint8Array;
  fileName: string;
}

export interface UploadCSVFileResponse {
  success: boolean;
  fileKey: string;
}

export interface QuoteSegment {
  text: string;
  position: number;
  startIndex: number;
  endIndex: number;
}

export interface QuoteExtract {
  extractedSpeakerName: string;
  extractedSpeakerTitle: string;
  extractedSpeakerFunction: string;
  extractedSpeakerCompanyName: string;
  segments: QuoteSegment[];
}

export interface RawQuote {
  id: number;
  idRelationship: number;
  news: NewsArticle | undefined;
  quoteExtract: QuoteExtract | undefined;
}

export interface QuoteMentionScore {
  /** company that the speaker is talking about */
  mentionedIdCbiEntity: number;
  score: number;
  vendorReferences: number;
}

export interface Quote {
  id: number;
  rawQuote: RawQuote | undefined;
  mentionScores: QuoteMentionScore[];
  /** company match that the quoted speaker belongs to */
  speakerIdCbiEntity: number;
  speakerEntityName: string;
  quoteLength: number;
}

export interface RelationshipNewsRawQuotes {
  idRelationship: number;
  news: NewsArticle | undefined;
  quoteExtracts: QuoteExtract[];
}

export interface RelationshipNewsQuotes {
  idRelationship: number;
  news: NewsArticle | undefined;
  quotes: RelationshipNewsQuotes_RelationshipNewsQuote[];
}

export interface RelationshipNewsQuotes_RelationshipNewsQuote {
  quoteExtract: QuoteExtract | undefined;
  mentionScores: QuoteMentionScore[];
  /** company match that the quoted speaker belongs to */
  speakerIdCbiEntity: number;
  speakerEntityName: string;
  quoteLength: number;
}

export interface ListCustomerReferencesRequest {
  idCbiEntity: number;
}

export interface ListCustomerReferencesResponse {
  /** sorted by score descending */
  references: ListCustomerReferencesResponse_Reference[];
}

export interface ListCustomerReferencesResponse_Reference {
  idCbiEntity: number;
  quote: Quote | undefined;
}

export interface BatchListCustomerReferencesRequest {
  idCbiEntities: number[];
}

export interface BatchListCustomerReferencesResponse {
  referencesLists: BatchListCustomerReferencesResponse_ReferencesList[];
}

export interface BatchListCustomerReferencesResponse_ReferencesList {
  idCbiEntity: number;
  quotes: Quote[];
}

export interface BatchCreateRawQuotesRequest {
  rawQuotes: RawQuote[];
}

export interface BatchCreateRawQuotesResponse {
  rawQuotes: RawQuote[];
}

export interface BatchCreateQuotesRequest {
  quotes: Quote[];
}

export interface BatchCreateQuotesResponse {
  quotes: Quote[];
}

export interface BatchCreateRelationshipNewsRawQuotesRequest {
  relationshipNewsRawQuotes: RelationshipNewsRawQuotes[];
}

export interface BatchCreateRelationshipNewsRawQuotesResponse {
  rawQuotes: RawQuote[];
}

export interface BatchCreateRelationshipNewsQuotesRequest {
  relationshipNewsQuotes: RelationshipNewsQuotes[];
}

export interface BatchCreateRelationshipNewsQuotesResponse {
  quotes: Quote[];
}

export interface ListQuotesRequest {
  idQuoteFilter: number[];
  mentionedIdCbiEntityFilter: number[];
  pageSize: number;
}

export interface ListQuotesResponse {
  quotes: Quote[];
}

export interface GetRelationshipSummariesRequest {
  idCbiEntity: number;
  idRelationships: number[];
  limit: number;
  offset: number;
}

export interface GetRelationshipSummariesResponse {
  relationshipSummaries: RelationshipSummary[];
}

export interface RelationshipSummary {
  idRelationship: number;
  startDate: string;
  headline: string;
  details: string[];
  sources: string[];
  playerProfiles: PlayerProfile[];
}

export interface PlayerProfile {
  companyName: string;
  idCbiEntity: number;
  url: string;
}
