// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v2.4.1
//   protoc               v5.29.0
// source: cbinsights/draftqueueservice/draftqueueservice.proto

/* eslint-disable */

export const protobufPackage = "draftqueueservice";

/** version 1.13.0 */

export enum DraftType {
  ENTITY_DRAFT = "ENTITY_DRAFT",
  FUNDING_DRAFT = "FUNDING_DRAFT",
  UNRECOGNIZED = "UNRECOGNIZED",
}

/** The enum should match the ids in the database and cbi entity service */
export enum SourceType {
  UNKNOWN_SOURCE_TYPE = "UNKNOWN_SOURCE_TYPE",
  INVESTOR_PROFILE = "INVESTOR_PROFILE",
  BUSINESS_RELATIONSHIP = "BUSINESS_RELATIONSHIP",
  TOP_SEARCH = "TOP_SEARCH",
  DATA_REQUEST = "DATA_REQUEST",
  CLIENT_REQUEST = "CLIENT_REQUEST",
  REVENUE = "REVENUE",
  SERVICE_PROVIDER = "SERVICE_PROVIDER",
  BR_PLAYER_ID = "BR_PLAYER_ID",
  QUALTRICS_JOB = "QUALTRICS_JOB",
  GEO_COVERAGE = "GEO_COVERAGE",
  PERSON_ADMIN = "PERSON_ADMIN",
  CHROME_EXTENSION = "CHROME_EXTENSION",
  TOP_SEARCH_CBI_EMPLOYEE = "TOP_SEARCH_CBI_EMPLOYEE",
  API_ORG_SEARCH = "API_ORG_SEARCH",
  COLLECTIONS_URL_UPLOADS = "COLLECTIONS_URL_UPLOADS",
  COLLECTIONS_URL_CBI_EMPLOYEE = "COLLECTIONS_URL_CBI_EMPLOYEE",
  TECH_RELATED = "TECH_RELATED",
  NEWS_QUEUE = "NEWS_QUEUE",
  CUSTOMER_REQUEST = "CUSTOMER_REQUEST",
  OTHER_SOURCE = "OTHER_SOURCE",
  UNRECOGNIZED = "UNRECOGNIZED",
}

/** The enum should match the ids in the database */
export enum DraftStatus {
  UNKNOWN_STATUS = "UNKNOWN_STATUS",
  READY_FOR_ENRICHMENT = "READY_FOR_ENRICHMENT",
  READY_FOR_QA = "READY_FOR_QA",
  UNRECOGNIZED = "UNRECOGNIZED",
}

export enum RoundType {
  FUNDING = "FUNDING",
  MA = "MA",
  OTHER = "OTHER",
  UNRECOGNIZED = "UNRECOGNIZED",
}

export interface ClearSimpleQueueByUserRequest {
}

export interface ClearSimpleQueueByUserResponse {
  success: boolean;
}

export interface GetSimpleQueueDraftsRequest {
  limit: number;
  idSimpleQueueDraftType: number;
}

export interface GetSimpleQueueDraftsResponse {
  simpleQueueDrafts: SimpleQueueDraft[];
}

export interface SimpleQueueDraft {
  idSimpleQueueDraft: number;
  serializedDraft: string;
}

export interface DiscardSimpleQueueDraftRequest {
  idSimpleQueueDraft: number;
}

export interface DiscardSimpleQueueDraftResponse {
  success: boolean;
}

export interface GetDraftCountsRequest {
}

export interface GetDraftCountsResponse {
  publishedDraftCount: number;
  pendingDraftCount: number;
  entityDraftCounts: EntityDraftCounts | undefined;
  fundingDraftCounts: FundingDraftCounts | undefined;
}

export interface EntityDraftCounts {
  enrichmentDraftCount: number;
  qaDraftCount: number;
  publishedDraftCount: number;
}

export interface FundingDraftCounts {
  publishedDraftCount: number;
  pendingDraftCount: number;
}

export interface ListDraftsRequest {
  idCbiEntities: number[];
  idDraftEntities: string[];
  /** The filters apply to all the drafts */
  generalFilters: GeneralFilter[];
  /** The filters only apply to funding */
  fundingDraftFilters: FundingFilter[];
  limit: number;
  draftType: DraftType;
}

export interface GeneralFilter {
  drafterFilter?: DrafterFilter | undefined;
  dateFilter?: DateFilter | undefined;
  statusFilter?: StatusFilter | undefined;
  sourceTypeFilter?: SourceTypeFilter | undefined;
}

export interface StatusFilter {
  draftStatuses: DraftStatus[];
}

export interface SourceTypeFilter {
  sourceTypes: SourceType[];
}

export interface DateFilter {
  startDate: string;
  endDate: string;
}

export interface DrafterFilter {
  idDrafters: number[];
}

export interface FundingFilter {
  roundFilter?: RoundFilter | undefined;
  excludeMentionsOnlyDraftFilter?: ExcludeMentionsOnlyDraftFilter | undefined;
}

export interface RoundFilter {
  roundTypes: RoundType[];
}

export interface ExcludeMentionsOnlyDraftFilter {
  enabled: boolean;
}

export interface ListDraftsResponse {
  entityDrafts: EntityDraft[];
}

export interface EntityDraft {
  idCbiEntity: number;
  name: string;
  url: string;
  fundingInfo: FundingInfo | undefined;
  draftDate: string;
  draftSummary: DraftSummary | undefined;
  idEntityDraft: string;
  draftStatus: DraftStatus;
  creationDraftDate: string;
}

export interface DraftSummary {
  lastModifiedUser: User | undefined;
  generalInfoDraftSummary: GeneralInfoDraftSummary | undefined;
  fundingDraftSummary: FundingDraftSummary | undefined;
}

export interface User {
  idUser: number;
  firstName: string;
  lastName: string;
}

export interface GeneralInfoDraftSummary {
  fieldCount: number;
}

export interface FundingDraftSummary {
  fieldCount: number;
  fundingCount: number;
}

export interface FundingInfo {
  idFunding: number;
  idFundingDraft: string;
  fundingRound: FundingRound | undefined;
  amount: number;
  minValuation: number;
  maxValuation: number;
}

export interface FundingRound {
  idRound: number;
  name: string;
}

export interface GetDraftersRequest {
  startDate: string;
  endDate: string;
  pendingRecordsOnly?: boolean | undefined;
  publishedRecordsOnly?: boolean | undefined;
}

export interface GetDraftersResponse {
  drafters: User[];
}

export interface UnlockEntitiesRequest {
  idCbiEntities: number[];
  idEntityDrafts: string[];
}

export interface UnlockEntitiesResponse {
  success: boolean;
}
